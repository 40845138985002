<template>
  <div class="text-center">
    <v-menu
      outlined 
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="redeValida?  '' :  'black' "
          outlined 
          v-bind="attrs"
          v-on="on"
          :style="redeValida ? 'border: 1px solid #9575CD; color: #9575CD;' :  'border: none; color: ##29074B;background-color: #FFCA28;' "
        >
          <span v-if="redeValida">{{nomeRede}}</span>
          <span v-if="!redeValida">Wrong network</span>
        </v-btn>
      </template>

      <v-list style="background-color: #111;">
   
        <v-list-item-group
          v-model="model"
          active-class="bg-active"
          class="bgWhite"
          style="color: yellow;"
        >

        <v-list-item
          v-for="(item, index) in redes"
          :key="index">
          <v-list-item-title @click="changeNetwork(item)" style="color:#fff;">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    data: () => ({
      errado: 0, 
      redes: [
        { title: 'BSC testenet', network: '0x61'}, 
        // { title: 'Ethereum', network: '0x1'},
        // { title: 'BSC', network: '0x38'},
        // { title: 'Matic Mumbai testnet', network: '0x13881'},
        // { title: 'Matic', network: '0x89'}
      ],
    }),
    methods: {
      changeNetwork(item) {
        this.$eth.request({ method: 'wallet_switchEthereumChain', params:[{chainId: item.network}]});
      }
    },
    computed: {
      chainId () {
        return this.$store.state.chainId  
      },
      nomeRede () {
        this.errado = 0
        for (var n in this.redes) {
          if (this.redes[n].network == this.chainId) return this.redes[n].title; 
          console.log('item: ', this.redes[n])
        }
        // if (this.chainId == 0x61) return "BSC testnet";
        // if (this.chainId == 1) return "Ethereum";
        this.errado = 1
      },
      redeValida () {
        // return ((this.chainId == 1) || (this.chainId == 0x61) || (this.chainId == 0x38)  )
        return (this.chainId == 0x61) 
      }
    }
  }
</script>

<style scoped="1">

.bg-active {
  background-color: #29074B !important;
  color : #fff !important;
}


.bgWhite{
  background-color: #29074B !important;
  cursor:  pointer;
}


</style>