<template>

  <div >



  <div class="divA">
    <p class="titulo-1">Lastest bets</p>

    <div clas="divB">
        <table class="tabela-resultados">
          <tr>
            <td></td>
            <td>Result</td>
            <td>Player</td>
            <td>Prize</td>
          </tr>
          <tbody v-for="item in lastGames">
          <tr>
            <td>{{item.id}}</td>
            <td>
              <img :src="mostra_img(0,item)" class="img1">
              <img :src="mostra_img(1,item)" class="img1">
              <img :src="mostra_img(2,item)" class="img1">
            </td>
            <td>{{abreviaAddress(item.player)}}</td>
            <td><span v-if="item.prize>0">{{formataNum(item.prize  / (10 ** 18),6)}}</span></td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  </div>
</template>

<script>

  import Func from '../plugins/func' 

  export default {
    name: 'lastestBets',
     mixins: [Func], 
    components: {  },
  props: {
    lastGames: Array
  },
  data: function() {
    return {
      imagens: [],
      n_imagens: 0,
      ires1: 0,
      ires2: 0,
      ires3: 0,
      // modo: 'inicio'  
    }
     
  },
  created () {
    this.imagens[0] = require('@/assets/bar.png')
    this.imagens[1] = require('@/assets/cereja.png')
    this.imagens[2] = require('@/assets/limao.png')
    this.imagens[3] = require('@/assets/melancia.png')
    this.imagens[4] = require('@/assets/uva.png')
    this.imagens[5] = require('@/assets/777.png')
    this.imagens[6] = require('@/assets/bigwin.png')
    this.imagens[7] = require('@/assets/banana.png')
    this.imagens[8] = require('@/assets/jackpot.png')
    this.imagens[9] = require('@/assets/trevo.png')
    this.n_imagens = this.imagens.length;
  },
  methods: {
    mostra_img(coluna, item) {
      var n = String(item.resultado)
      while (n.length<3) n = '0'+n;
      return this.imagens[ n.substr(coluna, 1) ];
    }
  }
 } 
  
/*

@use postcss-cssnext;
:root {
  --tileSize: 90px;
}

  top: calc(var(--tileSize) * 1 * 1.1666);

*/

</script>

<style scoped="1">

:root {
  --alturaImg: 90px;
}

.divA {
  height: 830px; 
  max-height:900px; 
  overflow: auto;  
  position: relative; 
  border: 1px solid #555;  
  background-color: #29074B;
  padding: 10px;
  border-radius: 8px;
}

.divB {
  position:absolute; 

}

.div10 {
  /*border:  1px red solid;*/
  width:  140px;
  height: 270px;
  padding:  3px;
  overflow:auto;
  position: relative;
}

.div15 {
  top:  -50px;
  position: absolute;
}

.div20 {
  width: 146px;
  min-height: 130px;
  text-align: center;
  /*position: absolute;*/
}


.tabela-resultados {
  width:  440px;
  padding:  6px;

}

.tabela-resultados td {
    padding:  4px;
    text-align: center;
    color:  #ddd;
    font-size:  18px;
}

.img1 {
  width:  30px;
}

.titulo-1 {
  margin-top: 20px;
  font-size: 24px;
  color:  #B39DDB;
}

</style>