<template>

  <div>

    <div class="visor" >
      <slotReel :modo="modo" :resultado="numeroAleatorio1" tempoFinal="0" /> 
      <slotReel :modo="modo" :resultado="numeroAleatorio2" tempoFinal="1"/> 
      <slotReel :modo="modo" :resultado="numeroAleatorio3" tempoFinal="2"/> 
    </div>

  </div>
</template>

<script>

    import slotReel from './slotReel'

  export default {
    name: 'slotMachine',
    components: { slotReel },
    props: {
      modo: {type: String},
      resultado: {type: String}  
    },
  data: function() {
    return {
        // roda1: 'inicio',
        // roda2: 'inicio',
        // roda3: 'inicio',
        numeroAleatorio1: '0',
        numeroAleatorio2: '0',
        numeroAleatorio3: '0'
    }    
  },
  created() {
      this.$root.$refs.maquinaCacaNiquel = this;
  },
  methods: { 
      pararRoda () {
        if (this.roda1 == 'girando') {
          const n_itens = 10;
          this.numeroAleatorio1 = Math.floor(Math.random() * n_itens);
          this.roda1='resultado'
          this.numeroAleatorio2 = Math.floor(Math.random() * n_itens);
          this.roda2='resultado'
          this.numeroAleatorio3 = Math.floor(Math.random() * n_itens);
          this.roda3='resultado'
        }
      },
      girarRoda () {
        console.log('girando roda')
        // this.roda1='girando'
        // this.roda2='girando'
        // this.roda3='girando'
        var that = this 
        setTimeout( function() {
          that.pararRoda ()
        }, 1800)
      },
      resetarRoda () {
        // this.roda1='inicio'
        // this.roda2='inicio'
        // this.roda3='inicio'
      },
      setResultado (newVal) {
          this.numeroAleatorio1 = newVal.substr(0,1);
          // this.roda1='resultado'
          this.numeroAleatorio2 = newVal.substr(1,1);
          // this.roda2='resultado'
          this.numeroAleatorio3 = newVal.substr(2,1);
          // this.roda3='resultado'
          console.log('numeroAleatorio 1 2 3', this.numeroAleatorio1, this.numeroAleatorio2, this.numeroAleatorio3)        
      }
    },
    // watch: {
    //   resultado: {
    //     // deep: true,
    //     // immediate: true,
    //     handler: function (newVal, oldVal) {
    //       console.log('passando no watch resultado - this:',this)
    //     // if (this.modo == 'girando') {
    //       // const n_itens = 10;
    //       this.numeroAleatorio1 = newVal.substr(0,1);
    //       // this.roda1='resultado'
    //       this.numeroAleatorio2 = newVal.substr(1,1);
    //       // this.roda2='resultado'
    //       this.numeroAleatorio3 = newVal.substr(2,1);
    //       // this.roda3='resultado'
    //       console.log('numeroAleatorio 1 2 3', numeroAleatorio1, numeroAleatorio2, numeroAleatorio3)
    //     // }
    //   }
    //   }
    // }
 } 
  
/*

@use postcss-cssnext;
:root {
  --tileSize: 90px;
}

  top: calc(var(--tileSize) * 1 * 1.1666);

*/

</script>

<style scoped="1">

.botao {
  border:  3px solid grey;
  padding: 6px;
  margin:  4px;
  border-radius: 6px;
  background-color: lightblue;
}

.visor {
  background-image: url('~@/assets/reel_overlay.png');
  padding-top: 10px;
  padding-left:  0px;
  display:  flex;
  width:  435px;
  height: 293px;
}

</style>