<template>
    <div>
      
          <table class="tabela-premios">
            <tr>
              <td style="color: #FFCA28; font-size: 18px; font-weight: bold; height: 50px;" colspan=3>
                Prizes: 1% chance
              </td>
            </tr>
            <tr style="font-weight: bold;">
              <td>Result</td>
              <td>% of <br>Jackpot</td>
              <td>Chance<br> to win</td>
            </tr>


            <tr style="font-weight: bold; color: orange;">
              <td><img src='@/assets/jackpot.png' class="imagemResultado"><img src="@/assets/jackpot.png" class="imagemResultado"><img src="@/assets/jackpot.png" class="imagemResultado"></td>
              <td>100%</td>
              <td>0.1%</td>
            </tr>

            
            <tr>
              <td><img src='@/assets/bar.png' class="imagemResultado"><img src="@/assets/bar.png" class="imagemResultado"><img src="@/assets/bar.png" class="imagemResultado"></td>
              <td>20%</td>
              <td>0.1%</td>
            </tr>

            <tr>
              <td><img src='@/assets/777.png' class="imagemResultado"><img src="@/assets/777.png" class="imagemResultado"><img src="@/assets/777.png" class="imagemResultado"></td>
              <td>20%</td>
              <td>0.1%</td>
            </tr>

            <tr>
              <td><img src='@/assets/bigwin.png' class="imagemResultado"><img src="@/assets/bigwin.png" class="imagemResultado"><img src="@/assets/bigwin.png" class="imagemResultado"></td>
              <td>20%</td>
              <td>0.1%</td>
            </tr>
            
            <tr>
             <td><img src='@/assets/trevo.png' class="imagemResultado"><img src="@/assets/trevo.png" class="imagemResultado"><img src="@/assets/trevo.png" class="imagemResultado"></td>
              <td>20%</td>
              <td>0.1%</td>
            </tr>
            <tr>
              <td><img src='@/assets/cereja.png' class="imagemResultado"><img src="@/assets/cereja.png" class="imagemResultado"><img src="@/assets/cereja.png" class="imagemResultado"></td>
              <td>10%</td>
              <td>0.1%</td>
            </tr>
            <tr>  
              <td><img src='@/assets/limao.png' class="imagemResultado"><img src="@/assets/limao.png" class="imagemResultado"><img src="@/assets/limao.png" class="imagemResultado"></td>
              <td>10%</td>
              <td>0.1%</td>
            </tr>
            <tr> 
              <td><img src='@/assets/melancia.png' class="imagemResultado"><img src="@/assets/melancia.png" class="imagemResultado"><img src="@/assets/melancia.png" class="imagemResultado"></td>
              <td>10%</td>
              <td>0.1%</td>
            </tr>
            <tr>
              <td><img src='@/assets/uva.png' class="imagemResultado"><img src="@/assets/uva.png" class="imagemResultado"><img src="@/assets/uva.png" class="imagemResultado"></td>
              <td>10%</td>
              <td>0.1%</td>
            </tr>
            <tr>
              <td><img src='@/assets/banana.png' class="imagemResultado"><img src="@/assets/banana.png" class="imagemResultado"><img src="@/assets/banana.png" class="imagemResultado"></td>
              <td>10%</td>
              <td>0.1%</td>
            </tr>
          </table>

  
          <table class="tabela-premios">
            <tr>
              <td style="color: #FFCA28; font-size: 18px; font-weight: bold; height: 50px;" colspan=3>
                Prizes: 20% chance
              </td>
            </tr>
            <tr style="font-weight: bold;">
              <td>Result</td>
              <td>% of <br>Jackpot</td>
              <td>Chance<br> to win</td>
            </tr>


            <tr>
              <td><img src="@/assets/jackpot.png" class="imagemResultado"><img src="@/assets/jackpot.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>

            
            <tr>
              <td><img src="@/assets/bar.png" class="imagemResultado"><img src="@/assets/bar.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>

            <tr>
              <td><img src="@/assets/777.png" class="imagemResultado"><img src="@/assets/777.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>

            <tr>
              <td><img src="@/assets/bigwin.png" class="imagemResultado"><img src="@/assets/bigwin.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>
            
            <tr>
             <td><img src="@/assets/trevo.png" class="imagemResultado"><img src="@/assets/trevo.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>
            <tr>
              <td><img src="@/assets/cereja.png" class="imagemResultado"><img src="@/assets/cereja.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>
            <tr>  
              <td><img src="@/assets/limao.png" class="imagemResultado"><img src="@/assets/limao.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>
            <tr> 
              <td><img src="@/assets/melancia.png" class="imagemResultado"><img src="@/assets/melancia.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>
            <tr>
              <td><img src="@/assets/uva.png" class="imagemResultado"><img src="@/assets/uva.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>
            <tr>
              <td><img src="@/assets/banana.png" class="imagemResultado"><img src="@/assets/banana.png" class="imagemResultado"></td>
              <td>1%</td>
              <td>2%</td>
            </tr>
          </table>        
    </div>
</template>

<script>
  export default {
    name: 'tabelaPremios',
    data () {
      return {
       }
    }
  }
  
</script>

<style scoped=1>
.imagemResultado {
  width:  30px;
}


.tabela-premios {
  border:  1px solid #555;
  padding:  3px;
  border-radius: 5px;
  width:  300px;
  margin-top: 42px;
  background-color: #29074B;
}

.tabela-premios td {
  text-align: center;
}
</style>