
<template>

  <div >
    <div class="div10">
      <div :class='tira' v-if="(modo=='inicio' || modo=='girando') " >
        <div class='div20' v-for="i in imagens"><img :src="i" class="figura"></div> 
        <div class='div20' v-for="i in imagens.slice(0,3)"><img :src="i" class="figura"></div>
      </div>
      <div :class='tira' v-if="(modo=='resultado')" >
        <div class='div20'><img :src="imagens[ires1]" class="figura"></div>
        <div class='div20'><img :src="imagens[ires2]" class="figura"></div>
        <div class='div20'><img :src="imagens[ires3]" class="figura"></div>
      </div>
    </div>



  </div>
</template>

<script>

    // import slotReel from './slotReel'

  export default {
    name: 'slotReel',
    components: {  },
  props: {
    modo: String,
    resultado: String,
    tempoFinal: String  
  },
  data: function() {
    return {
      imagens: [],
      n_imagens: 0,
      ires1: 0,
      ires2: 0,
      ires3: 0,
      audio1: null 
      // modo: 'inicio'  
    }
     
  },
  created () {
    this.imagens[0] = require('@/assets/bar.png')
    this.imagens[1] = require('@/assets/cereja.png')
    this.imagens[2] = require('@/assets/limao.png')
    this.imagens[3] = require('@/assets/melancia.png')
    this.imagens[4] = require('@/assets/uva.png')
    this.imagens[5] = require('@/assets/777.png')
    this.imagens[6] = require('@/assets/bigwin.png')
    this.imagens[7] = require('@/assets/banana.png')
    this.imagens[8] = require('@/assets/jackpot.png')
    this.imagens[9] = require('@/assets/trevo.png')
    this.audio1 = new Audio('https://freesound.org/data/previews/56/56246_91374-lq.mp3') 
    this.n_imagens = this.imagens.length;
  },
  computed: {
    tira () {
      var resposta = ""
      var t = 0 
      this.ires2 =  Math.floor(this.resultado);
      this.ires1 = (this.ires2 == 0) ? this.n_imagens - 1 :   this.ires2 - 1
      this.ires3 = (this.ires2 == (this.n_imagens - 1)) ? 0 :   this.ires2 + 1

      if (this.modo == 'inicio') return "div15"
      if (this.modo == 'girando') return "div15 animacao1"
      if (this.modo == 'resultado') {
        this.audio1.play() 
        // var r = Math.floor(Math.random() * 4);
        if (this.tempoFinal == "0") {
          resposta = "div15 animacao2a"
          t = 1000 
        }
        if (this.tempoFinal == "1") {
          resposta = "div15 animacao2b";
          t = 1200
        } 
        if (this.tempoFinal == "2") {
          resposta = "div15 animacao2c";
          t = 1400
        }
        if (this.tempoFinal == "3") {
          resposta = "div15 animacao2d";
          t = 1600 
        }
        var that = this
        setTimeout( function() {
          that.audio1.play()
        }, t)
        return resposta;
      }  
    }
  }
 } 
  
/*

@use postcss-cssnext;
:root {
  --tileSize: 90px;
}

  top: calc(var(--tileSize) * 1 * 1.1666);

*/

</script>

<style scoped="1">

:root {
  --alturaImg: 90px;
}

.div10 {
  /*border:  1px red solid;*/
  width:  140px;
  height: 270px;
  padding:  3px;
  overflow: hidden;
  position: relative;
}

.div15 {
  top:  -50px;
  position: absolute;
}

.div20 {
  width: 146px;
  min-height: 130px;
  text-align: center;
  /*position: absolute;*/
}

.animacao1 {
  animation: rapido1 0.3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.animacao2a {
  animation: shakeY2;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}


.animacao2b {
  animation: shakeY2;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

.animacao2c {
  animation: shakeY2;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
}

.animacao2d {
  animation: shakeY2;
  animation-duration: 1.6s;
  animation-fill-mode: forwards;
}


.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  animation-iteration-count: infinite;
}

@keyframes rapido1 {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-600px);
  }
}

@keyframes shakeY2{
  0%,to{
    -webkit-transform:translateZ(0);
    transform:translateZ(0)
  }
  10%,30%,50%,70%,90%{
    -webkit-transform:translate3d(0,-40px,0);
    transform:translate3d(0,-40px,0)
  }
  20%,40%,60%,80%{
    -webkit-transform:translate3d(0,40px,0);
    transform:translate3d(0,40px,0)
    }
  }

.figura {
  width: 120px;
}
</style>