<template>
  <v-app>
    <v-app-bar  color="#140336" flat   height="70px">

      <v-btn v-if="this.$route.name === 'Home'" class="link1" outlined style="border: none;" to="/Terms">Support & Terms</v-btn>&nbsp;&nbsp;
      <v-btn v-if="this.$route.name !== 'Home'" class="link1" outlined style="border: none;" to="/">Back</v-btn>&nbsp;&nbsp;


     <v-spacer></v-spacer>
      <div class="d-flex align-center " >
          <img src='@/assets/logo.png' class="imagemResultado" style="width:200px; margin-top: 50px;">
      </div>

      <v-spacer></v-spacer>

      <btnNetwork />
      &nbsp;&nbsp;&nbsp;

      <v-btn  :class="colorBtnAccount"  @click="conectar">

          
        <span v-if="userAccount">{{abreviaAddress(userAccount) }}</span>
        <span v-if="!userAccount">Connect</span>
 
      </v-btn>&nbsp;&nbsp;
    </v-app-bar>

    <v-main class="classe_fundo_app">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

  import Func from './plugins/func' 
  import btnNetwork from './components/btnNetwork'

  export default {
    name: 'App',
    mixins: [Func], 
    components: {  btnNetwork },
    data: () => ({
      //
    }),
    methods: {
      conectar () {
        if (!this.userAccount) {
          this.$eth.send('eth_requestAccounts')
        }
      }
    },
    computed :{
    userAccount () {
        return this.$store.state.ethAccount  
      },
      colorBtnAccount () {
        if (this.userAccount) {
          return "deep-purple darken-1 white--text"
        } else {
          return "amber lighten-1 black--text"
        }
      }
    }
  };
</script>

<style scoped="1">

.classe_fundo_app {
  background-image: linear-gradient(#140336, #6f1193);
}

.link1 {
  color: #9575CD;
  background-color:transparent !important;
}

</style>