import Vue from 'vue'
import Vuex from 'vuex'
// import state from './state'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true, 
  state: {
    ethAccount: '',
    redeAtual: 'bsc_test',
    chainId: 0x61  
  },
  mutations: { 
    setEthAccount(state, newAccount) {
      state.ethAccount = newAccount
    },
    setRede(state, novaRede) {
      state.redeAtual = novaRede
    },
    setChainId(state, n) {
      state.chainId = n
    }
  },
  actions: {},
  // modules: { }
})
